import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { IonButton, IonCheckbox, IonCol, IonLabel, IonRow, IonSpinner } from '@ionic/react'
import { reviewOrderStyles } from './Styles/ReviewOrderStyles'
import { AuthContext } from '../auth'
import BarberSummary from './Summary/BarberSummary'
import DateTimeSummary from './Summary/DateTimeSummary'
import ServicesProductsSummary from './Summary/ServicesProductsSummary'
import NoFutureApptsCard from '../commonComponents/NoFutureApptsCard'
import LocationSummary from './Summary/LocationSummary'

export default function Review({
  choosecustomer,
  setStep,
  coreAppointment,
  userSubscription,
  scheduleData,
  updateScheduleData,
  stepNext,
  isWalkin,
  step,
  trackStep,
}) {
  const { userData, getUserData, businessData } = useContext(AuthContext)

  let [errorMessage, setErrorMessage] = useState('')
  let [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
  const [notificationsEnabled, setNotificationsEnabled] = useState(true)
  const [userNotificationsEnabled, setUserNotificationsEnabled] = useState(false)
  const [apptCount, setApptCount] = useState(0)
  const [cardOnFile, setCardOnFile] = useState(false)

  const maxLocationAppts = scheduleData?.location?.appointmentSettings?.futureAppointmentsLimit

  let userCreatedEpochTime = ''
  let isCreatedMoreThanOneHrAgo = false
  if (userData?.createdDate) {
    userCreatedEpochTime = userData?.createdDate
    const oneHourInMilliseconds = 60 * 60 * 1000 // 1 hour in milliseconds

    const currentEpochTime = Date.now()

    //checkingif the account was create more than one hr ago
    isCreatedMoreThanOneHrAgo = userCreatedEpochTime < currentEpochTime - oneHourInMilliseconds
  }
  const displaySettings = !userNotificationsEnabled && isCreatedMoreThanOneHrAgo
  const getCustomerAppointmentCount = async () => {
    if (userData?._id) {
      try {
        const response = await axios.get(`appointment_v2/count?customerId=${userData?._id}`)
        setApptCount(response?.data?.count || 0)
      } catch (error) {}
    }
  }
  const getCard = async () => {
    if (scheduleData?.location._id && userData?.locations?.some((loc) => loc._id === scheduleData?.location?._id)) {
      let response = await axios.get(`/customer_v2/cardStripe?locationId=${scheduleData?.location._id}`)
      setCardOnFile(response?.data?.cardOnFile || false)
    } else {
      setCardOnFile(false)
    }
  }
  useEffect(() => {
    trackStep('ReviewOrder', step, scheduleData)

    if (userData) {
      getCard()
      getCustomerAppointmentCount()
    }
  }, [])
  const toggleNotifications = () => {
    setNotificationsEnabled(!notificationsEnabled)
  }

  const updateNotificationSettings = () => {
    if (userData?.notificationSettings?.textNotificationsEnabled) {
      setUserNotificationsEnabled(true)
    }
  }
  useEffect(() => {
    updateNotificationSettings()
  }, [userData])
  const updateUserNotifications = async () => {
    let selectedUserData = { ...userData }
    const notificationSettings = {
      ...selectedUserData.notificationSettings,
      textNotificationsEnabled: true,
      emailNotificationsEnabled: true,
    }
    selectedUserData['notificationSettings'] = notificationSettings

    try {
      let response = await axios.patch(`/customer_v2?id=${userData?._id}`, selectedUserData)
      if (response.status == 200) {
        getUserData()
      }
    } catch (error) {}
  }
  async function handleBookAndPay() {
    if (displaySettings) {
      updateUserNotifications()
    }
    setErrorMessage('')
    setSubmitButtonDisabled(true)
    const source = sessionStorage.getItem('refid') // Retrieve the source from session storage
    let appointmentInput = {
      businessId: scheduleData.businessId,
      locationId: scheduleData.location?._id,
      customerId: scheduleData.customer?._id,
      barberId: scheduleData.barber?._id,
      requestedBarberId: scheduleData?.requestedBarber?._id,
      anyProfessional: scheduleData?.anyProfessional,
      services: scheduleData.services,
      selectedDate: scheduleData.dateTime?.dateText,
      startTime: scheduleData.dateTime?.startTime,
      endTime: scheduleData.dateTime?.endTime,
      startTimeText: scheduleData.dateTime?.startTimeText,
      endTimeText: scheduleData.dateTime?.endTimeText,
      durationMin: scheduleData?.durationMin,
      payment: scheduleData?.payment,
      type: isWalkin ? 'Walkin' : 'Appointment',
      agreedToCancelPolicy: scheduleData?.agreedToCancelPolicy,
    }
    if (source) {
      appointmentInput.source = source
    }
    console.log('appointmentInput', appointmentInput)
    try {
      let response = await axios.post(`/appointment_v2`, appointmentInput)
      if (response.status == 200) {
        stepNext()
      } else {
        setErrorMessage('Sorry, that appointment is no longer available. Please choose another time.')
      }
      setSubmitButtonDisabled(false)
    } catch (error) {
      setErrorMessage('Sorry, that appointment is no longer available. Please choose another time.')
      setSubmitButtonDisabled(false)
    }
  }

  function handleTipClick(value) {
    updateScheduleData('payment', { ...scheduleData.payment, ...{ tipPercentage: value } })
  }

  return (
    <React.Fragment>
      {/* <HeaderComponent title={'Review and Pay'} handleBack={stepBack} progress={{ total: totalSteps, step: step + 1 }} /> */}
      <div style={{ marginLeft: 14, marginRight: 14 }}>
        {isWalkin ? (
          <>
            <h2>Reserve Your Spot In Line</h2>
            <ServicesProductsSummary mode='create' setStep={setStep} appointment={coreAppointment || scheduleData} />

            <br></br>
          </>
        ) : (
          <>
            <div
              style={{
                width: '100%',
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 32,
                display: 'inline-flex',
              }}
            >
              <div
                style={{
                  alignSelf: 'stretch',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 28,
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    alignSelf: 'stretch',
                    paddingLeft: 10,
                    paddingRight: 10,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 16,
                    display: 'flex',
                  }}
                >
                  <div style={reviewOrderStyles.containerStyle}>
                    <div style={reviewOrderStyles.titleStyle}>Lets Make Sure Everything Looks Right</div>
                    <div style={reviewOrderStyles.subContainerStyle}>
                      <div style={reviewOrderStyles.innerContainerStyle}>
                        <div style={reviewOrderStyles.innerContentStyle}>
                          <div style={reviewOrderStyles.textContainerStyle}>
                            <div style={reviewOrderStyles.textStyle}>
                              <div style={reviewOrderStyles.headingStyle}>Appointment Details</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      alignSelf: 'stretch',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      gap: 24,
                      display: 'flex',
                    }}
                  >
                    <div style={{ width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex' }}>
                      <BarberSummary setStep={setStep} appointment={coreAppointment || scheduleData} businessData={businessData} />

                      <DateTimeSummary setStep={setStep} appointment={coreAppointment || scheduleData} />
                      <LocationSummary setStep={setStep} appointment={coreAppointment || scheduleData} />
                    </div>
                  </div>
                </div>
                <div style={{ width: 390, height: 6, justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                  <div style={{ width: 390, height: 6, background: '#F3F3F3' }} />
                </div>
              </div>
              <div style={{ alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', display: 'flex' }}>
                <div
                  style={{
                    alignSelf: 'stretch',
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 16,
                    display: 'flex',
                  }}
                >
                  <ServicesProductsSummary mode='create' setStep={setStep} appointment={coreAppointment || scheduleData} />
                </div>
              </div>
            </div>
          </>
        )}
        {displaySettings && (
          <IonRow onClick={toggleNotifications}>
            <IonCol size='auto'>
              <IonCheckbox mode='ios' checked={notificationsEnabled} onIonChange={toggleNotifications} labelPlacement='end' />
            </IonCol>
            <IonCol>
              <IonLabel style={{ textAlign: 'left', fontSize: 12 }}>
                I agree to receive texts & emails reminding me about my appointment and marketing messages. Data rates may apply. Go to notification settings in
                profile to opt out.
              </IonLabel>
            </IonCol>
          </IonRow>
        )}
        {apptCount >= maxLocationAppts ? <NoFutureApptsCard apptCount={apptCount} locationId={scheduleData.location} /> : null}
      </div>
      <style>
        {`
        @keyframes shimmer {
          0% { background-position: -600px; }
          40% { background-position: 0px; }
          100% { background-position: 300px; } /* Hold position to create delay */
        }
      `}
      </style>

      <div style={{ position: 'sticky', bottom: 0, paddingTop: 10, paddingBottom: 14, borderTop: '2px solid #e0e0e0', background: 'white' }}>
      {errorMessage && <div style={{ color: 'red', textAlign: 'center', marginBottom:10 }}>{errorMessage}</div>}
        <div
          style={{
            margin: '0 10px',
            borderRadius: 5,
            backgroundImage: 'linear-gradient(135deg, #0054e9 0%, #0054e9 40%, #ffffff 50%, #0054e9 60%, #0054e9 100%)',
            backgroundSize: '200%',
            animation: 'shimmer 4s linear infinite',
          }}
        >
          {/* IonButton with transparent background */}
          <IonButton
            mode='ios'
            style={{
              '--background': 'transparent', // Allows shimmer effect to show through
            }}
            disabled={submitButtonDisabled}
            onClick={handleBookAndPay}
            expand='block'
          >
            {submitButtonDisabled ? <IonSpinner name='dots' /> : `${isWalkin ? 'Check In Now' : 'Schedule Appointment'}`}
          </IonButton>
        </div>
      </div>
    </React.Fragment>
  )
}
