import { IonButton, IonButtons, IonCard, IonContent, IonLabel, IonModal, IonSpinner } from '@ionic/react'
import { useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import ConfirmationModal from '../commonComponents/ConfirmationModal'

export default function CancelAppointment({ cancellationCharges, cardOnFile, appointment, isCanceling, handleCancel, locationData }) {
  const isCustomerSubscribed = appointment?.customer?.isSubscribed
  const locationCancelationSettings = isCustomerSubscribed
    ? locationData?.appointmentSettings?.cancellationSettings?.subscriber
    : locationData?.appointmentSettings?.cancellationSettings?.nonSubscriber

  const [modalOpen, setModalOpen] = useState(false)
  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }

  const currentTime = new Date().getTime()
  const timeDifferenceInHours = (appointment?.startTime - currentTime) / (60 * 60 * 1000)
  const cancellationAllowed = timeDifferenceInHours > (locationCancelationSettings?.hoursBefore || 24)

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: 15,
          marginTop: 10,
          marginBottom: -15,
          width: '100%',
        }}
      >
        <IonButton
          disabled={isCanceling}
          onClick={openModal}
          expand='block'
          fill='outline'
          color={'danger'}
          style={{ fontFamily: 'Montserrat', width: '100%' }}
        >
          {isCanceling ? <IonSpinner name='dots' color='black' style={{ color: 'black' }} /> : 'Cancel Appointment'}
        </IonButton>
      </div>
      <IonModal isOpen={modalOpen}>
        <CommonHeader title={'Cancel Appointment'} />
        <IonContent className='regularBackground'>
          <IonCard style={{ padding: '1rem' }}>
            {locationCancelationSettings?.charge ? (
              <>
                {cardOnFile && !cancellationAllowed && appointment?.agreedToCancelPolicy ? (
                  <>
                    <IonLabel style={{ fontWeight: 'bold' }}>
                      {`Since you are canceling within ${
                        locationCancelationSettings?.hoursBefore || 24
                      } hours of the appointment you will be charged a ${cancellationCharges} fee. Would you still like to cancel?`}
                    </IonLabel>
                  </>
                ) : (
                  <h2 style={{ textAlign: 'center' }}>{'Are you sure you want to cancel this appointment'}</h2>
                )}

                <IonButtons
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '1rem',
                  }}
                >
                  <IonButton style={{ color: 'grey' }} onClick={() => closeModal()}>
                    Dismiss
                  </IonButton>
                  {isCanceling && <IonSpinner name='lines' />}
                  {!isCanceling && (
                    <IonButton onClick={() => handleCancel(true)}>
                      {cardOnFile && !cancellationAllowed && appointment?.agreedToCancelPolicy ? `Cancel & Pay $${cancellationCharges}` : 'Cancel Appointment'}
                    </IonButton>
                  )}
                </IonButtons>
              </>
            ) : (
              <>
                <ConfirmationModal
                  message={'Are you sure you want to cancel this appointment'}
                  cancel={() => closeModal()}
                  loading={isCanceling}
                  save={() => handleCancel(false)}
                  cancelText={'Dismiss'}
                  saveText={'Cancel Appointment'}
                />
              </>
            )}
          </IonCard>
        </IonContent>
      </IonModal>
    </>
  )
}
